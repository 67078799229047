import React, {useEffect, useState} from "react";
import {dataItemUpdate} from "../../../actions";
import {connect} from "react-redux";
import { DataGrid, ruRU } from '@mui/x-data-grid';
import { Eventcalendar, localeRu, snackbar, setOptions, Select, Popup, Button, Input, Textarea, Switch, Datepicker, SegmentedGroup, SegmentedItem } from '@mobiscroll/react';
import '@mobiscroll/react/dist/css/mobiscroll.min.css'
import GridGroups from "./grid-registries/grid-groups";
import GridLessons from "./grid-registries/grid-lessons";
import GridTeachers from "./grid-registries/grid-teachers";
import GridRooms from "./grid-registries/grid-rooms";
import GridPlans from "./grid-registries/grid-plans";
import GridSpecializations from "./grid-registries/grid-specializations";
import GridCafedras from "./grid-registries/grid-cafedras";
import GridStreams from "./grid-registries/grid-streams";
import GridSeminarGroups from "./grid-registries/grid-seminar-groups";
import GridSeminarSpecGroups from "./grid-registries/grid-seminar-spec-groups";
import GridPlansTotal from "./grid-registries/grid-plans-total";
import APIService from "../../../services/api-service";
import GridTeachersNames from "./grid-registries/grid-teachers-names";

const GROUPS = 'groups'
const SEMINAR_GROUPS = 'seminar-groups'
const SEMINAR_GROUP_SPECIALISATIONS = 'seminar-group-specialisations'
const STREAMS = 'streams'
const LEARNING_PLAN_TOTAL = 'learning-plan-total'
const LEARNING_PLANS = 'learning-plans'
const LESSONS = 'lessons'
const CAFEDRAS = 'cafedras'
const TEACHERS = 'teachers'
const TEACHERS_TYPES = 'teachers-types'
const ROOMS = 'rooms'
const SPECIALIZATIONS = 'specializations'

const apiService = new APIService()

const toTwoDigits = (num) => `${num < 10 ? '0' : ''}${num}`;

const toBrowserDate = (date) => {
    return `${date.getFullYear()}-${toTwoDigits(date.getMonth() + 1)}-${toTwoDigits(date.getDate())}`;
}

const days = ['2023-04-17', '2023-04-18', '2023-04-19', '2023-04-20', '2023-04-21']

const inputProps = {
    inputStyle: 'box',
    labelStyle: 'stacked',
    placeholder: 'Please select...'
};

let autoFillIndex = 0;
let timer = 0;

const groups = [
    {id: 1, name: '401/1', specialization: 'Зарубежная литература', secondary: 'итальянский', comment: 'Зарубежная литература, итальянский'},
    {id: 2, name: '401/2', specialization: 'Зарубежная литература', secondary: 'испанский', comment: 'Зарубежная литература, испанский'},
    {id: 3, name: '402/1', specialization: 'Страноведение', secondary: 'французский', comment: 'Страноведение, французский'},
    {id: 4, name: '402/2', specialization: 'Страноведение', secondary: 'французский', comment: 'Страноведение, французский'},
    /*
    {name: '403/1', specialization: 'Страноведение', secondary: 'испанский', comment: 'Страноведение, испанский'},
    {name: '403/2', specialization: 'Страноведение', secondary: 'итальянский', comment: 'Страноведение, итальянский'},
    {name: '404/1', specialization: 'Страноведение', secondary: 'немецкий', comment: 'Страноведение, немецкий'},
    {name: '404/2', specialization: 'Страноведение', secondary: 'немецкий', comment: 'Страноведение, немецкий'},
    {name: '405/1', specialization: 'Русский как иностранный', secondary: 'немецкий', comment: 'Русский как иностранный, немецкий'},
    {name: '405/2', specialization: 'Русский как иностранный', secondary: 'немецкий', comment: 'Русский как иностранный, немецкий'},
    {name: '406/1', specialization: 'Русский как иностранный', secondary: 'французский', comment: 'Русский как иностранный, французский'},
    {name: '406/2', specialization: 'Русский как иностранный', secondary: 'итальянский', comment: 'Русский как иностранный, итальянский'},
    {name: '407/1', specialization: 'Русский как иностранный', secondary: 'испанский', comment: 'Русский как иностранный, испанский'},
    {name: '408/2', specialization: 'Зарубежная литература', secondary: 'немецкий', comment: 'Зарубежная литература, немецкий'},
    {name: '409/1', specialization: 'Компьютерная лингвистика', secondary: 'немецкий', comment: 'Компьютерная лингвистика, немецкий'},
    {name: '409/2', specialization: 'Компьютерная лингвистика', secondary: 'испанский', comment: 'Компьютерная лингвистика, испанский'},
    {name: '410/1', specialization: 'Компьютерная лингвистика', secondary: 'немецкий', comment: 'Компьютерная лингвистика, немецкий'},
    {name: '410/2', specialization: 'Компьютерная лингвистика', secondary: 'итальянский', comment: 'Компьютерная лингвистика, итальянский'},
    {name: '411/1', specialization: 'Компьютерная лингвистика', secondary: 'французский', comment: 'Компьютерная лингвистика, французский'},
    {name: '411/2', specialization: 'Ортофония', secondary: 'испанский', comment: 'Ортофония, испанский'},
    {name: '412/1', specialization: 'Зарубежная литература', secondary: 'польский', comment: 'Зарубежная литература, польский'},
    {name: '412/2', specialization: 'Зарубежная литература', secondary: 'французский', comment: 'Зарубежная литература, французский'},
    {name: '413/1', specialization: 'Зарубежная литература', secondary: 'немецкий', comment: 'Зарубежная литература, немецкий'},
    {name: '413/2', specialization: 'Зарубежная литература', secondary: 'японский', comment: 'Зарубежная литература, японский'},
    {name: '414/1', specialization: 'Зарубежная литература', secondary: 'турецкий', comment: 'Зарубежная литература, турецкий'},
    {name: '414/2', specialization: 'Зарубежная литература', secondary: 'арабский', comment: 'Зарубежная литература, арабский'},
    {name: '415/1', specialization: 'Зарубежная литература', secondary: 'китайский', comment: 'Зарубежная литература, китайский'},
    {name: '415/2', specialization: 'Зарубежная литература', secondary: 'корейский', comment: 'Зарубежная литература, корейский'},
    {name: '416/1', specialization: 'Зарубежная литература', secondary: 'французский', comment: 'Зарубежная литература, французский'},
    {name: '416/2', specialization: 'Зарубежная литература', secondary: 'немецкий', comment: 'Зарубежная литература, немецкий'},
    {name: '417/1', specialization: 'Риторика', secondary: 'немецкий', comment: 'Риторика, немецкий'},
    {name: '417/2', specialization: 'Риторика', secondary: 'французский', comment: 'Риторика, французский'},
    {name: '418/1', specialization: 'Риторика', secondary: 'испанский', comment: 'Риторика, испанский'},
    {name: '418/2', specialization: 'Риторика', secondary: 'итальянский', comment: 'Риторика, итальянский'},
    {name: '419/1', specialization: 'Белорусский как иностранный', secondary: 'немецкий', comment: 'Белорусский как иностранный, немецкий'},
    {name: '419/2', specialization: 'Белорусский как иностранный', secondary: 'испанский', comment: 'Белорусский как иностранный, испанский'},


     */
]

const streams = [
    { name: 'Поток 1', groups: ['401/1', '401/2', '402/1', '402/2', '403/1', '403/2', '404/1', '404/2']},
    { name: 'Поток 2', groups: ['405/1', '405/2', '406/1', '406/2', '407/1', '408/2']},
    { name: 'Поток 3', groups: ['409/1', '409/2', '410/1', '410/2', '411/1', '411/2', '412/1', '412/2']},
    { name: 'Поток 4', groups: ['413/1', '413/2', '414/1', '414/2', '415/1', '415/2', ]},
    { name: 'Поток 5', groups: ['416/1', '416/2', '417/1', '417/2', '418/1', '418/2', '419/1', '419/2' ]},
]

const seminarGroups = [
    { name: 'Семинарская 1', groups: ['401/1', '401/2']},
    { name: 'Семинарская 2', groups: ['402/1', '402/2']},
    { name: 'Семинарская 3', groups: ['403/1', '403/2']},
    { name: 'Семинарская 4', groups: ['404/1', '404/2']},
    { name: 'Семинарская 5', groups: ['405/1', '405/2']},
    { name: 'Семинарская 6', groups: ['406/1', '406/2']},
    { name: 'Семинарская 7', groups: ['407/1', '408/2']},
    { name: 'Семинарская 8', groups: ['409/1', '409/2']},
    { name: 'Семинарская 9', groups: ['410/1', '410/2']},
    { name: 'Семинарская 10', groups: ['411/1', '411/2']},
    { name: 'Семинарская 11', groups: ['412/1', '412/2']},
    { name: 'Семинарская 12', groups: ['413/1', '413/2']},
    { name: 'Семинарская 13', groups: ['414/1', '414/2']},
    { name: 'Семинарская 14', groups: ['415/1', '415/2']},
    { name: 'Семинарская 15', groups: ['416/1', '416/2']},
    { name: 'Семинарская 16', groups: ['417/1', '417/2']},
    { name: 'Семинарская 17', groups: ['418/1', '418/2']},
    { name: 'Семинарская 18', groups: ['419/1', '419/2']},

]

const seminarGroupsSpecialization = [
    { name: 'Семинарская 1 спец', groups: ['405/1', '405/2'], specialization: 'Русский как иностранный'},
    { name: 'Семинарская 2 спец', groups: ['406/1', '406/2'], specialization: 'Русский как иностранный'},
    { name: 'Семинарская 3 спец', groups: ['407/1'], specialization: 'Русский как иностранный'},
    { name: 'Семинарская 4 спец', groups: ['409/1', '409/2'], specialization: 'Компьютерная лингвистика'},
    { name: 'Семинарская 5 спец', groups: ['410/1', '410/2'], specialization: 'Компьютерная лингвистика'},
    { name: 'Семинарская 6 спец', groups: ['411/1'], specialization: 'Компьютерная лингвистика'},
    { name: 'Семинарская 7 спец', groups: ['419/1', '419/2'], specialization: 'Компьютерная лингвистика'},
]

const plansTotal = [
    {lesson: 'Стилистика (первый иностранный)', lections: 20, seminars: 14, self: 66, sem2: 2},
    {lesson: 'Лексикология (первый иностранный)', lections: 20, seminars: 14, self: 66, sem1: 2,},
    {lesson: 'Практика устной и письменной речи 2-го иностранного языка', practice: 136, self: 128, sem1: 4, sem2: 4},
    {lesson: 'Методика преподавания иностранных языков',  lections: 70, practice: 20, seminars: 34, self: 194, sem1: 4, sem2: 4},
    {lesson: 'Язык для специальных целей',  practice: 34, self: 38, sem2: 2},
    {lesson: 'Тенденции развития современной зарубежной литературы',  lections: 34, self: 38, sem2: 2},
]

const lessons = [
    { text: 'Культурология', value: 'Культурология' },
    { text: 'Лексикология (первый иностранный)', value: 'Лексикология (первый иностранный)' },
    { text: 'Язык СМИ 1', value: 'Язык СМИ 1' },
    { text: 'Язык СМИ 2', value: 'Язык СМИ 2' },
    { text: 'Теоретическая грамматика (первый иностранный)', value: 'Теоретическая грамматика (первый иностранный)' },
    { text: 'Стилистика (первый иностранный)', value: 'Стилистика (первый иностранный)' },
    { text: 'Основы методологии научного исследования', value: 'Основы методологии научного исследования' },
    { text: 'Лингвострановедение второго иностранного языка', value: 'Лингвострановедение второго иностранного языка' },
    { text: 'Интерпретация художественного текста', value: 'Интерпретация художественного текста' },
    { text: 'Интерпретация коммуникативного поведения', value: 'Интерпретация коммуникативного поведения' },
    { text: 'Практика устной и письменной речи 2-го иностранного языка', value: 'Практика устной и письменной речи 2-го иностранного языка' },
    { text: 'Стилистика (первый иностранный)', value: 'Стилистика (первый иностранный)' },
    { text: 'Культура США', value: 'Культура США' },
    { text: 'Методика преподавания иностранного языка', value: 'Методика преподавания иностранного языка' },
    { text: 'Введение в переводоведение', value: 'Введение в переводоведение' },
    { text: 'Язык для специальных целей', value: 'Язык для специальных целей' },
    { text: 'Физическая культура', value: 'Физическая культура' },
    { text: 'Тенденции развития современной зарубежной литературы', value: 'Тенденции развития современной зарубежной литературы' },
];

const cafedras = [
    { name: 'Лексикологии английского языка'},
    { name: 'Лигводидактики и методики преподавания иностранных языков'},
    { name: 'Информатики и прикладной лингвистики'},
    { name: 'Зарубежной литературы'},
    { name: 'Речеведения и теории коммуникации'},
    { name: 'Белорусского языка и литературы'},
    { name: 'Второго иностранного языка (немецкий)'},
    { name: 'Лексикологии французского языка'},
    { name: 'Фонетики и грамматики французского языка'},
    { name: 'Фонетики и грамматики испанского языка'},
    { name: 'Итальянского языка'},
    { name: 'Теории и практики китайского языка'},
    { name: 'Восточных языков'},
];

const teachers = [
    { text: 'Бетеня Е.П.', cafedra: 'Лексикологии английского языка', value: 'Бетеня Е.П.', lessons: 'Культурология', type:'Лекция',  comment: '', groups: [], groupNotes: '' },
    { text: 'Бетеня Е.П.', cafedra: 'Лексикологии английского языка', value: 'Бетеня Е.П.', lessons: 'Лексикология (первый иностранный)', type:'Лекция',  comment: 'Поставить во вторник 14.30', groupNotes: 'Поток №1 401/1-404/2', stream: 'Поток 1'},
    { text: 'Бекреева Ю.В.', cafedra: 'Лексикологии английского языка', value: 'Бекреева Ю.В.', lessons: 'Лексикология (первый иностранный)', type:'Лекция', groupNotes: 'Поток №2 405/1-408/2', stream: 'Поток 2' },
    { text: 'Курилович О.Ф.', cafedra: 'Лексикологии английского языка', value: 'Курилович О.Ф.', lessons: 'Лексикология (первый иностранный)', type:'Лекция', groupNotes: 'Поток №3 409/1-412/2', stream: 'Поток 3'},
    { text: 'Курилович О.Ф.', cafedra: 'Лексикологии английского языка', value: 'Курилович О.Ф.', lessons: 'Язык СМИ 1', type:'Лекция', groupNotes: ''},
    { text: 'Волковинская Н. А.', cafedra: 'Лексикологии английского языка', value: 'Волковинская Н. А.', lessons: 'Лексикология (первый иностранный)', type: 'Лекция', comment: 'Не ставить в субботу и в 17.30', groupNotes: 'Поток №4 413/1-415/2', stream: 'Поток 4'},
    { text: 'Волковинская Н. А.', cafedra: 'Лексикологии английского языка', value: 'Волковинская Н. А.', lessons: 'Лексикология (первый иностранный)', type: 'Семинар', comment: 'Не ставить в субботу и в 17.30', groupNotes: '3 семинарские группы', groupsNum: 3},
    { text: 'Комаристова И. И.', cafedra: 'Лексикологии английского языка', value: 'Комаристова И. И.', lessons: 'Лексикология (первый иностранный)', type: 'Лекция', groupNotes: 'Поток №5 416/1-419/2', stream: 'Поток 5' },
    { text: 'Комаристова И. И.', cafedra: 'Лексикологии английского языка', value: 'Комаристова И. И.', lessons: 'Лексикология (первый иностранный)', type: 'Семинар', groupNotes: '4 семинарские группы', stream: 'Поток 5', groupsNum: 4,  comment: 'В потоке № 5' },
    { text: 'Комаристова И. И.', cafedra: 'Лексикологии английского языка', value: 'Комаристова И. И.', lessons: 'Язык СМИ 2', type: 'Практика', groupNotes: '6', comment: 'Только в группах со специализацией страноведение (402/1-404/2). Не ставить в субботу и понедельник', groupsNum: 6, specialization: 'Страноведение'},
    { text: 'Комаристова И. И.', cafedra: 'Лексикологии английского языка', value: 'Комаристова И. И.', lessons: 'Язык и общество США', type: 'Лекция', groupNotes: 'поток групп специализации страноведение 402/1-404/2', specialization: 'Страноведение' },
    { text: 'Окаева А. Б.', cafedra: 'Лексикологии английского языка', value: 'Окаева А. Б.', lessons: 'Лексикология (первый иностранный)', type: 'Семинар', groupNotes: '4 семинарские группы', comment: 'В потоке № 1', stream: 'Поток 1', groupsNum: 4},
    { text: 'Окаева А. Б.', cafedra: 'Лексикологии английского языка', value: 'Окаева А. Б.', lessons: 'Язык СМИ 2', type: 'Практика', groupNotes: '6', comment: '', groupsNum: 6},
    { text: 'Окаева А. Б.', cafedra: 'Лексикологии английского языка', value: 'Окаева А. Б.', lessons: 'Язык и общество США', type: 'Практика', groupNotes: '6 групп специализации страноведение', comment: '', groupsNum: 6, specialization: 'Страноведение'},
    { text: 'Окаева А. Б.', cafedra: 'Лексикологии английского языка', value: 'Окаева А. Б.', lessons: 'Культура США', type: 'Практика', groupNotes: '', comment: ''},
    { text: 'Забело Е. Ч.', cafedra: 'Лексикологии английского языка', value: 'Забело Е. Ч.', lessons: 'Лексикология (первый иностранный)', type: 'Семинар', groupNotes: '3 семинарские группы', comment: 'В потоке № 2', stream: 'Поток 2', groupsNum: 3},
    { text: 'Забело Е. Ч.', cafedra: 'Лексикологии английского языка', value: 'Забело Е. Ч.', lessons: 'Язык СМИ 2', type: 'Практика', groupNotes: '6 групп по языку сми2', groupsNum: 6},
    { text: 'Хомич В. В.', cafedra: 'Лексикологии английского языка', value: 'Хомич В. В.', lessons: 'Лексикология (первый иностранный)', type: 'Семинар', groupNotes: '4 семинарские группы', comment: 'В потоке № 3', stream: 'Поток 3', groupsNum: 4},
    { text: 'Хомич В. В.', cafedra: 'Лексикологии английского языка', value: 'Хомич В. В.', lessons: 'Язык СМИ 2', type: 'Практика', groupNotes: '5', comment: 'Поставить все занятия в три дня', groupsNum: 5},
    { text: 'Позняк К. Е.', cafedra: 'Лексикологии английского языка', value: 'Позняк К. Е.', lessons: 'Язык СМИ 1', type:'Практика', groupNotes: '7', comment: 'Не ставить в один день больше трех пар', groupsNum: 7},
    { text: 'Позняк К. Е.', cafedra: 'Лексикологии английского языка', value: 'Позняк К. Е.', lessons: 'Интерпретация художественного текста', type: 'Практика', groupNotes: '5 со специализацией ЗЛ', comment: 'Не ставить в один день больше трех пар', groupsNum: 5, specialization: 'Зарубежная литература' },
    { text: 'Гилеп О. Г.', cafedra: 'Лексикологии английского языка', value: 'Гилеп О. Г.', lessons: 'Язык СМИ 1', type: 'Практика', groupNotes: '2', comment: '', groupsNum: 2},
    { text: 'Гилеп О. Г.', cafedra: 'Лексикологии английского языка', value: 'Гилеп О. Г.', lessons: 'Язык СМИ 2', type: 'Практика', groupNotes: '5', groupsNum: 5},
    { text: 'Спириденок Ю.М.', cafedra: 'Лексикологии английского языка', value: 'Спириденок Ю.М.', lessons: 'Язык СМИ 1', type: 'Практика', groupNotes: '10', groupsNum: 10},
    { text: 'Спириденок Ю.М.', cafedra: 'Лексикологии английского языка', value: 'Спириденок Ю.М.', lessons: 'Интерпретация коммуникативного поведения', type: 'Практика', groupNotes: '2 группы специализации риторика', groupsNum: 2, specialization: 'Риторика'},
    { text: 'Драгун К.С.', cafedra: 'Лексикологии английского языка', value: 'Драгун К.С.', lessons: 'Язык СМИ 1', type: 'Практика', groupNotes: '10', groupsNum: 10},
    { text: 'Маслёнченко Н.А.', cafedra: 'Лексикологии английского языка', value: 'Маслёнченко Н.А.', lessons: 'Язык СМИ 1', type: 'Практика', groupNotes: '4', comment: 'Ставить занятия предпочтительно в субботу. Можно в пятницу', groupsNum: 4},
    { text: 'Зинина О. А.', cafedra: 'Лексикологии английского языка', value: 'Зинина О. А.', lessons: 'Язык СМИ 1', type: 'Практика', groupNotes: '1', comment: 'Не ставить в субботу и в 17.30', groupsNum: 1},
    { text: 'Котов К.А.', cafedra: 'Лексикологии английского языка', value: 'Котов К.А.', lessons: 'Язык СМИ 1', type: 'Практика', groupNotes: '1', comment: '', groupsNum: 1},
    { text: 'Котов К.А.', cafedra: 'Лексикологии английского языка', value: 'Котов К.А.', lessons: 'Язык для специальных целей', type: 'Практика', groupNotes: '', comment: ''},
    { text: 'Лойша М.М.', cafedra: 'Лексикологии английского языка', value: 'Лойша М.М.', lessons: 'Язык СМИ 1', type: 'Практика', groupNotes: '1', comment: 'В одной из групп со специализацией риторика, поставить все занятия в три дня', groupsNum: 1},
    { text: 'Кротюк Е.В.', cafedra: 'Лексикологии английского языка', value: 'Кротюк Е.В.', lessons: 'Язык СМИ 2', type: 'Практика', groupNotes: '7', comment: 'поставить все занятия в четыре дня', groupsNum: 7},
    { text: 'Нехайчик Д.И.', cafedra: 'Лексикологии английского языка', value: 'Нехайчик Д.И.', lessons: 'Язык СМИ 2', type: 'Практика', groupNotes: '5', comment: '', groupsNum: 5},

    { text: 'Кудрявцева И. К.', cafedra: 'Зарубежной литературы', value: 'Кудрявцева И. К.', lessons: 'Интерпретация художественного текста', type:'Лекция', groupsNum: 2, specialization: 'Зарубежная литература', groupNotes: '2 потока (группы со специализацией ЗЛ) '},
    { text: 'Смирнова М.В.', cafedra: 'Зарубежной литературы', value: 'Смирнова М.В.', lessons: 'Интерпретация художественного текста', type:'Практика', groupNotes: '3 со специализацией ЗЛ', groupsNum: 3, specialization: 'Зарубежная литература'},
    { text: 'Смирнова М.В.', cafedra: 'Зарубежной литературы', value: 'Смирнова М.В.', lessons: 'Интерпретация художественного текста', type:'Практика', groupNotes: '3 со специализацией ЗЛ', groupsNum: 3, specialization: 'Зарубежная литература'},

    { text: 'Колосовская И.Г.', cafedra: 'Лигводидактики и методики преподавания иностранных языков', value: 'Колосовская И.Г.', lessons: 'Методика преподавания иностранного языка', type:'Лекция', stream: 1, groupNotes: 'Поток №1 401/1-404/2'},
    { text: 'Костенко Н.Р.', cafedra: 'Лигводидактики и методики преподавания иностранных языков', value: 'Костенко Н.Р.', lessons: 'Методика преподавания иностранного языка', type:'Лекция', stream: 2, groupNotes: 'Поток №2 405/1-408/2'},
    { text: 'Антошина И.М.', cafedra: 'Лигводидактики и методики преподавания иностранных языков', value: 'Антошина И.М.', lessons: 'Методика преподавания иностранного языка', type:'Семинар', groupNotes: '4 семинарские группы, любые', groupsNum: 4, comment: 'Ведет на другом факультете'},
    { text: 'Лукина С.А.', cafedra: 'Лигводидактики и методики преподавания иностранных языков', value: 'Лукина С.А.', lessons: 'Методика преподавания иностранного языка', type:'Семинар',  groupNotes: '4 семинарские группы, любые', groupsNum: 7, comment: 'Любые, не ставить в пятницу'},
    { text: 'Чаплинская Е.О.', cafedra: 'Лигводидактики и методики преподавания иностранных языков', value: 'Чаплинская Е.О.', lessons: 'Методика преподавания иностранного языка', type:'Семинар',  groupNotes: '2 семинарские группы, Любые', groupsNum: 2, comment: 'Ведет на другом факультете'},

    { text: 'Токаревич Н.М.', cafedra: 'Лексикологии французского языка', language: 'французский', value: 'Токаревич Н.М.', lessons: 'Практика устной и письменной речи 2-го иностранного языка', type:'Практика', groupsNum: 3, comment: 'В 13.00 не ставить – занята на другом факультете'},
    { text: 'Козлова С.В.', cafedra: 'Лексикологии французского языка', language: 'французский', value: 'Козлова С.В.', lessons: 'Практика устной и письменной речи 2-го иностранного языка', type:'Практика', groupsNum: 2, comment: 'Работает на других курсах – расписание прилагается. Хочет обязательно один свободный день '},
    { text: 'Бондарчук Л.Г.', cafedra: 'Лексикологии французского языка', language: 'французский', value: 'Бондарчук Л.Г.', lessons: 'Практика устной и письменной речи 2-го иностранного языка', type:'Практика', groupsNum: 3, },
    { text: 'Галдукевич А.А.', cafedra: 'Лексикологии французского языка', language: 'французский', value: 'Галдукевич А.А.', lessons: 'Практика устной и письменной речи 2-го иностранного языка', type:'Практика', groupsNum: 4, comment: 'Работает на других курсах – расписание прилагается. Хочет обязательно один свободный день ' },
    { text: 'Бартош Н.Н.', cafedra: 'Лексикологии французского языка', language: 'французский', value: 'Бартош Н.Н.', lessons: 'Практика устной и письменной речи 2-го иностранного языка', type:'Практика', groupsNum: 4, comment: 'Работает на других курсах – расписание прилагается. Хочет обязательно один свободный день ' },

    { text: 'Ольховская Т.П.', cafedra: 'Фонетики и грамматики испанского языка', language: 'испанский', value: 'Ольховская Т.П.', lessons: 'Практика устной и письменной речи 2-го иностранного языка', type:'Практика', groupsNum: 4 },
    { text: 'Лещенко Н.В.', cafedra: 'Фонетики и грамматики испанского языка', language: 'испанский', value: 'Лещенко Н.В.', lessons: 'Практика устной и письменной речи 2-го иностранного языка', type:'Практика', groupsNum: 3 },
    { text: 'Нестюк Е.М.', cafedra: 'Фонетики и грамматики испанского языка', language: 'испанский', value: 'Нестюк Е.М.', lessons: 'Практика устной и письменной речи 2-го иностранного языка', type:'Практика', groupsNum: 2 },
    { text: 'Платунова К.П.', cafedra: 'Фонетики и грамматики испанского языка', language: 'испанский', value: 'Платунова К.П.', lessons: 'Практика устной и письменной речи 2-го иностранного языка', type:'Практика', groupsNum: 4 },
    { text: 'Шмат И.Ф.', cafedra: 'Фонетики и грамматики испанского языка', language: 'испанский', value: 'Шмат И.Ф.', lessons: 'Практика устной и письменной речи 2-го иностранного языка', type:'Практика', groupsNum: 4 },

    { text: 'Якубовская М.П.', cafedra: 'Итальянского языка', language: 'итальянский', value: 'Якубовская М.П.', lessons: 'Практика устной и письменной речи 2-го иностранного языка', type:'Практика', groupsNum: 2 },
    { text: 'Карпеш А.Э.', cafedra: 'Итальянского языка', language: 'итальянский', value: 'Карпеш А.Э.', lessons: 'Практика устной и письменной речи 2-го иностранного языка', type:'Практика', groupsNum: 2 },
    { text: 'Потапова М.В.', cafedra: 'Итальянского языка', language: 'итальянский', value: 'Потапова М.В.', lessons: 'Практика устной и письменной речи 2-го иностранного языка', type:'Практика', groupsNum: 1 },
    { text: 'Тропец Т.В.', cafedra: 'Итальянского языка', language: 'итальянский', value: 'Тропец Т.В.', lessons: 'Практика устной и письменной речи 2-го иностранного языка', type:'Практика', groupsNum: 5 },

    { text: 'Рогачевская М. С.', value: 'Рогачевская М. С.', lessons: 'Тенденции развития современной зарубежной литературы', type:'Лекция'},

    { text: 'Лось  Е. Е.', value: 'Лось  Е. Е.', lessons: 'Физическая культура', type:'Лекция'},

    { text: 'Козлова С. В.', value: 'Козлова С. В.', lessons: 'Лингвострановедение второго иностранного языка', type:'Практика', comment: 'один свободный день, занята на других курсах, Расписание прилагается'},
    { text: 'Авраменко В. И.', value: 'Авраменко В. И.', lessons: 'Лингвострановедение второго иностранного языка', type:'Практика', },
    { text: 'Потапова М. В.', value: 'Потапова М. В.', lessons: 'Лингвострановедение второго иностранного языка', type:'Практика', comment: 'занята на других курсах, Расписание прилагается, один свободный день'},
    { text: 'Шмат И. Ф.', value: 'Шмат И. Ф.', lessons: 'Лингвострановедение второго иностранного языка', type:'Практика'},

    { text: 'Будникова Е. И.', value: 'Будникова Е. И.', lessons: 'Стилистика (первый иностранный)', type:'Лекция'},

    { text: 'Бетеня Е.П.', cafedra: 'Лексикологии английского языка', value: 'Бетеня Е.П.', lessons: 'Культура США', type:'Лекция',  comment: '', groups: [], groupNotes: '', specialization: 'Страноведение'},

    /*
    { text: 'Козлова С. В.', value: 'Козлова С. В.', lessons: ['Лингвострановедение второго иностранного языка', 'Практика устной и письменной речи 2-го иностранного языка'], type:['Практика'], comment: 'один свободный день, занята на других курсах, Расписание прилагается'},
    { text: 'Ольховская Т. П.', value: 'Ольховская Т. П.', lessons: ['Практика устной и письменной речи 2-го иностранного языка'], type:['Практика'], comment: '2 группы по языку сми1'},
    { text: 'Лукина С. А.', value: 'Лукина С. А.', lessons: ['Методика преподавания иностранных языков'], type:['Семинар'], comment: 'не ставить в пятницу'},
    { text: 'Шмат И. Ф.', value: 'Шмат И. Ф.', lessons: ['Практика устной и письменной речи 2-го иностранного языка'], type:['Практика'], comment: 'занята на других курсах, Расписание прилагается, один свободный день'},
    { text: 'Потапова М. В.', value: 'Потапова М. В.', lessons: ['Практика устной и письменной речи 2-го иностранного языка', 'Лингвострановедение второго иностранного языка'], type:['Практика'], comment: 'занята на других курсах, Расписание прилагается, один свободный день'},
    { text: 'Карпеш А. Э.', value: 'Карпеш А. Э.', lessons: ['Практика устной и письменной речи 2-го иностранного языка', 'Лингвострановедение второго иностранного языка'], type:['Практика'], comment: 'занята на других курсах, Расписание прилагается, один свободный день'},
    { text: 'Кудрявцева И. К.', value: 'Кудрявцева И. К.', lessons: ['Интерпретация художественного текста'], type:['Лекция'], comment: 'группы со специализацией зарубежная литература'},
    { text: 'Будникова Е. И.', value: 'Будникова Е. И.', lessons: ['Стилистика (первый иностранный)'], type:['Лекция']},
    { text: 'Авраменко В. И.', value: 'Авраменко В. И.', lessons: ['Лингвострановедение второго иностранного языка'], type:['Практика']},
    { text: 'Токаревич Н. М.', value: 'Токаревич Н. М.', lessons: ['Практика устной и письменной речи 2-го иностранного языка'], type:['Практика'], comment: 'не ставить в 13.00'},
    { text: 'Антошина И. М.', value: 'Антошина И. М.', lessons: ['Методика преподавания иностранных языков'], type:['Семинар'], comment: 'ведет на другом факультете, Расписание прилагается'},
    { text: 'Кротюк Е. В.', value: 'Кротюк Е. В.', lessons: ['Язык СМИ 2'], type:['Практика'], comment: 'Поставить все занятия в четыре дня'},
    { text: 'Карась В. П.', value: 'Карась В. П.', lessons: ['Введение в переводоведение'], type:['Практика']},
    { text: 'Руденко  Т. Н.', value: 'Руденко  Т. Н.', lessons: ['Язык для специальных целей'], type:['Практика']},
    { text: 'Лось  Е. Е.', value: 'Лось  Е. Е.', lessons: ['Физическая культура'], type:['Семинар']},
    { text: 'Леонтьева Т. П.', value: 'Леонтьева Т. П.', lessons: ['Методика преподавания иностранных языков'], type:['Лекция']},
    { text: 'Мартыненко П. А.', value: 'Мартыненко П. А.', lessons: ['Введение в переводоведение'], type:['Практика']},
    { text: 'Драневич А. В.', value: 'Драневич А. В.', lessons: ['Введение в переводоведение'], type:['Практика']},
    { text: 'Смирнова М. В.', value: 'Смирнова М. В.', lessons: ['Интерпретация художественного текста'], type:['Практика']},
    { text: 'Рогачевская М. С.', value: 'Рогачевская М. С.', lessons: ['Тенденции развития современной зарубежной литературы'], type:['Лекция']},
    { text: 'Мартыненко П. А.', value: 'Мартыненко П. А.', lessons: ['Введение в переводоведение'], type:['Практика']},

     */
];

const rooms = [
    {text: 'В101/1 - семинары', value: 'В101/1', type: 'Семинар', comment: 'прожектор, синхр., ПЭВМ'},
    {text: 'В501/1 - семинары', value: 'В501/1', type: 'Семинар', comment: 'прожектор, синхр., ПЭВМ'},
    {text: 'Б303 - семинары', value: 'Б303', type: 'Семинар', comment: 'ТВ ЖКИ, онлайн-конференция'},
    {text: 'Б309 - практика', value: 'Б309', type: 'Практика', comment: 'ТВ ЖКИ, онлайн-конференция'},
    {text: 'Б402 - лекции', value: 'Б402', type: 'Лекция', comment: 'прожектор, микрофон'},
    {text: 'Б403 - практика', value: 'Б403', type: 'Практика', comment: 'ТВ ЖКИ'},
    {text: 'Б404 - практика', value: 'Б404', type: 'Практика', comment: 'кафедра речеведения'},
    {text: 'Б405 - практика', value: 'Б405', type: 'Практика', comment: 'фонетический класс'},
    {text: 'Б406 - практика', value: 'Б406', type: 'Практика', comment: 'e-доска'},
    {text: 'Б407 - семинары', value: 'Б407', type: 'семинар', comment: 'e-доска'},
    {text: 'Б408 - семинары', value: 'Б408', type: 'семинар', comment: 'e-доска'},
    {text: 'Б409 - семинары', value: 'Б409', type: 'семинар', comment: 'e-доска'},
    {text: 'Б410 - практика', value: 'Б410', type: 'Практика', comment: 'e-доска'},
    {text: 'Б416 - практика', value: 'Б416', type: 'Практика', comment: 'e-доска'},
    {text: 'Б419 - практика', value: 'Б419', type: 'Практика', comment: 'e-доска'},
    {text: 'В209 - практика', value: 'В209', type: 'Практика', comment: 'e-доска'},
    {text: 'В505 - практика', value: 'В505', type: 'Практика', comment: 'e-доска'},
    {text: 'В507 - практика', value: 'В507', type: 'Практика', comment: 'e-доска'},
    {text: 'В511 - практика', value: 'В511', type: 'Практика', comment: 'e-доска'},
    {text: 'В212 - практика', value: 'В212', type: 'Практика', comment: 'e-доска'},
    {text: 'Д206 - практика', value: 'Д206', type: 'Практика', comment: 'e-доска'},
    {text: 'Д213 - практика', value: 'Д213', type: 'Практика', comment: 'e-доска'},
    {text: 'Д404 - практика', value: 'Д404', type: 'Практика', comment: 'e-доска'},
    {text: 'Д408 - практика', value: 'Д408', type: 'Практика', comment: 'e-доска'},
    {text: 'Д414 - практика', value: 'Д414', type: 'Практика', comment: 'e-доска'},
    {text: 'Д410 - лекции', value: 'Д410', type: 'Лекция', comment: 'e-доска'},
]

const specializations = [
    {name: 'Русский как иностранный', groups: []},
    {name: 'Компьютерная лингвистика', groups: []},
    {name: 'Зарубежная литература', groups: ['401/1', '401/2']},
    {name: 'Страноведение', groups: ['402/1', '402/2']},
    {name: 'Ортофония', groups: []},
    {name: 'Риторика', groups: []},
    {name: 'Белорусский как иностранный', groups: []},
]

const plans = [

    {weekDay: '2023-05-22', lesson: 'Методика преподавания иностранного языка', hours: 2, type: 'Лекция', course: 4},
    {weekDay: '2023-05-22', lesson: 'Методика преподавания иностранного языка', hours: 2, type: 'Практика', course: 4},

    {weekDay: '2023-05-22', specialization: 'Зарубежная литература', lesson: 'Интерпретация художественного текста', hours: 2, type: 'Лекция', course: 4},
    {weekDay: '2023-05-22', specialization: 'Зарубежная литература', lesson: 'Интерпретация художественного текста', hours: 2, type: 'Практика', course: 4},

    {weekDay: '2023-05-22', specialization: '', lesson: 'Язык СМИ 1', hours: 2, type: 'Практика', course: 4},
    {weekDay: '2023-05-22', specialization: '', lesson: 'Язык СМИ 2', hours: 2, type: 'Практика', course: 4},

    {weekDay: '2023-05-22', specialization: '', lesson: 'Практика устной и письменной речи 2-го иностранного языка', hours: 4, type: 'Практика', course: 4},

    {weekDay: '2023-05-22', specialization: '', lesson: 'Тенденции развития современной зарубежной литературы', hours: 2, type: 'Лекция', course: 4},

    {weekDay: '2023-05-22', specialization: '', lesson: 'Лингвострановедение второго иностранного языка', hours: 4, type: 'Практика', course: 4},

    {weekDay: '2023-01-22', specialization: '', lesson: 'Стилистика (первый иностранный)', hours: 2, type: 'Лекция', course: 4},

    {weekDay: '2023-01-22', specialization: 'Страноведение', lesson: 'Культура США', hours: 2, type: 'Лекция', course: 4},

]

const timesTill = {
    "11:15": "12:35",
    "13:00": "14:20",
    "14:30": "15:50",
    "16:00": "17:20",
    "17:30": "18:50",
}

const proceedDay = (eventsDay=[], index) => {
    const date = days[index]
    return eventsDay.map(el => {
        const timeFrom = el.time
        const timeTo = timesTill[el.time]
        return {
            ...el,
            start: `${date}T${timeFrom}:00.000Z`,
            end: `${date}T${timeTo}:00.000Z`
        }
    })
}

const eventMonday = [
]

const eventTuesday = [
    /*{time:"13:00", title: "Введение в переводоведение", teacher: "Мартыненко П. А.", room: "", type: 'Практика', "resource":2},*/
    {time:"13:00", title: "Практика устной и письменной речи 2-го иностранного языка", teacher: "Токаревич Н. М.", room: "", type: 'Практика', "resource":3},
    {time:"13:00", title: "Практика устной и письменной речи 2-го иностранного языка", teacher: "Козлова С. В.", room: "", type: 'Практика', "resource":4},
    {time:"14:30", title: "Язык СМИ 1", teacher: "Зинина  О. А.", room: "В212", type: 'Практика', "resource":1},
    {time:"14:30", title: "Лингвострановедение второго иностранного языка", teacher: "Шмат И. Ф.", room: "В511", type: 'Практика', "resource":2},
    {time:"14:30", title: "Культура США", teacher: "Бетеня Е. П.", room: "В501", type: 'Лекция', "resource":3},
    {time:"14:30", title: "Культура США", teacher: "Бетеня Е. П.", room: "В501", type: 'Лекция', "resource":4},
    {time:"16:00", title: "Лингвострановедение второго иностранного языка", teacher: "Потапова М. В.", room: "", type: 'Практика', "resource":1},
    {time:"16:00", title: "Язык СМИ 1", teacher: "Гилеп О. Г.", room: "", type: 'Практика', "resource":2},
    {time:"16:00", title: "Методика преподавания иностранных языков", teacher: "Антошина И. М.", room: "", type: 'Лекция', "resource":3},
    {time:"16:00", title: "Методика преподавания иностранных языков", teacher: "Антошина И. М.", room: "", type: 'Лекция', "resource":4},
    /*{time:"17:30", title: "Язык для специальных целей", teacher: "Котов К. А.", room: "Б104", type: 'Практика', "resource":4},*/
]

const eventWednesday = [
    {time:"13:00", title: "Практика устной и письменной речи 2-го иностранного языка", teacher: "Карпеш А. Э.", room: "", type: 'Практика', "resource":1},
    {time:"14:30", title: "Интерпретация художественного текста", teacher: "Позняк  К. Е.", room: "", type: 'Практика', "resource":1},
    {time:"14:30", title: "Лингвострановедение второго иностранного языка", teacher: "Козлова С. В.", room: "", type: 'Практика', "resource":4},
    {time:"16:00", title: "Интерпретация художественного текста", teacher: "Кудрявцева И. К.", room: "", type: 'Лекция', "resource":1},
    {time:"16:00", title: "Интерпретация художественного текста", teacher: "Кудрявцева И. К.", room: "", type: 'Лекция', "resource":2},
    {time:"16:00", title: "Язык СМИ 1", teacher: "Позняк К. Е.", room: "", type: 'Практика', "resource":3},
    {time:"16:00", title: "Язык СМИ 2", teacher: "Комаристова И. И.", room: "", type: 'Практика', "resource":4},
    {time:"14:30", title: "Язык СМИ 1", teacher: "Гилеп О. Г.", room: "", type: 'Практика', "resource":2},
    {time:"17:30", title: "Лингвострановедение второго иностранного языка", teacher: "Авраменко В. И.", room: "", type: 'Практика', "resource":3},
]

const eventThursday = [
    {time:"13:00", title: "Практика устной и письменной речи 2-го иностранного языка", teacher: "Ольховская Т. П.", room: "", type: 'Практика', "resource":2},
    {time:"13:00", title: "Практика устной и письменной речи 2-го иностранного языка", teacher: "Козлова С. В.", room: "", type: 'Практика', "resource":4},
    {time:"14:30", title: "Тенденции развития современной зарубежной литературы", teacher: "Рогачевская М. С.", room: "", type: 'Лекция', "resource":1},
    {time:"14:30", title: "Тенденции развития современной зарубежной литературы", teacher: "Рогачевская М. С.", room: "", type: 'Лекция', "resource":2},
    {time:"14:30", title: "Тенденции развития современной зарубежной литературы", teacher: "Рогачевская М. С.", room: "", type: 'Лекция', "resource":3},
    {time:"14:30", title: "Тенденции развития современной зарубежной литературы", teacher: "Рогачевская М. С.", room: "", type: 'Лекция', "resource":4},
    {time:"16:00", title: "Лингвострановедение второго иностранного языка", teacher: "Потапова М. В.", room: "", type: 'Практика', "resource":1},
    {time:"16:00", title: "Язык для специальных целей", teacher: "Руденко Т. Н.", room: "", type: 'Практика', "resource":2},
    {time:"16:00", title: "Введение в переводоведение", teacher: "Карась В. П.", room: "", type: 'Практика', "resource":3},
    {time:"16:00", title: "Язык СМИ 1", teacher: "Спириденок Ю. М.", room: "", type: 'Практика', "resource":4},
    {time:"17:30", title: "Язык СМИ 2", teacher: "Кротюк Е. В.", room: "", type: 'Практика', "resource":1},
    /*{time:"17:30", title: "Культура США", teacher: "Окаева А. Б.", room: "Д206", type: 'Практика', "resource":3},*/
]

const eventFriday = [
    {time:"13:00", title: "Стилистика (первый иностранный)", teacher: "Будникова Е. И.", room: "", type: 'Лекция', "resource":1},
    {time:"13:00", title: "Стилистика (первый иностранный)", teacher: "Будникова Е. И.", room: "", type: 'Лекция', "resource":2},
    {time:"13:00", title: "Язык для специальных целей", teacher: "Руденко Т. Н.", room: "", type: 'Практика', "resource":3},
    {time:"13:00", title: "Введение в переводоведение", teacher: "Мартыненко П. А.", room: "", type: 'Практика', "resource":4},
    {time:"14:30", title: "Методика преподавания иностранных языков", teacher: "Леонтьева  Т. П.", room: "", type: 'Лекция', "resource":1},
    {time:"14:30", title: "Методика преподавания иностранных языков", teacher: "Леонтьева  Т. П.", room: "", type: 'Лекция', "resource":2},
    {time:"14:30", title: "Методика преподавания иностранных языков", teacher: "Леонтьева  Т. П.", room: "", type: 'Лекция', "resource":3},
    {time:"14:30", title: "Методика преподавания иностранных языков", teacher: "Леонтьева  Т. П.", room: "", type: 'Лекция', "resource":4},
]

const eventsWeekly = [

    ...proceedDay(eventMonday, 0),
    ...proceedDay(eventTuesday, 1),
    ...proceedDay(eventWednesday, 2),
    ...proceedDay(eventThursday, 3),
    ...proceedDay(eventFriday, 4),

]

const events = [
    {"start":"2023-03-27T14:30:00.000Z","end":"2023-03-27T15:50:00.000Z", "title":"Культурология", teacher: "Бетеня Е.П.", room: "Б402", "resource":1},
    {"start":"2023-03-27T16:00:00.000Z","end":"2023-03-27T17:20:00.000Z", "title":"Лексикология (первый иностранный)", teacher: "Бетеня Е.П.", room: "Б403", "resource":1},
    {"start":"2023-03-27T17:30:00.000Z","end":"2023-03-27T18:50:00.000Z", "title":"Язык СМИ 1", teacher: "Курилович О.Ф.", room: "Б404", "resource":1},
    {"start":"2023-03-27T14:30:00.000Z","end":"2023-03-27T15:50:00.000Z","title":"Язык СМИ 1", teacher: "Курилович О.Ф.", room: "Б405", "resource":2},
    {"start":"2023-03-27T16:00:00.000Z","end":"2023-03-27T17:20:00.000Z", "title":"Лексикология (первый иностранный)", teacher: "Бекреева Ю.В.", room: "Б406", "resource":2},
    {"start":"2023-03-27T16:00:00.000Z","end":"2023-03-27T17:20:00.000Z","title":"Язык СМИ 1", teacher: "Курилович О.Ф.", room: "Б407", "resource":3},
    {"start":"2023-03-27T17:30:00.000Z","end":"2023-03-27T18:50:00.000Z","title":"Культурология", teacher: "Бетеня Е.П.", room: "Б408", "resource":3},
    {"start":"2023-03-27T14:30:00.000Z","end":"2023-03-27T15:50:00.000Z","title":"Лексикология (первый иностранный)", teacher: "Бетеня Е.П.", room: "Б409", "resource":4},
    {"start":"2023-03-27T16:00:00.000Z","end":"2023-03-27T17:20:00.000Z","title":"Лексикология (первый иностранный)", teacher: "Бетеня Е.П.", room: "Б410", "resource":4},

]

const SchedulerUniverView = () => {

    const [registriesPage, setRegistriesPage] = useState(0)

    const [registryGroups, setRegistryGroups] = useState(groups || [])
    const [registryLessons, setRegistryLessons] = useState(lessons || [])
    const [registryTeachers, setRegistryTeachers] = useState(teachers || [])
    const [registryTeachersTypes, setRegistryTeachersTypes] = useState([])
    const [registryRooms, setRegistryRooms] = useState(rooms || [])
    const [registryPlans, setRegistryPlans] = useState(plans || [])
    const [registryPlansTotal, setRegistryPlansTotal] = useState(plansTotal || [])
    const [registryCafedras, setRegistryCafedras] = useState(cafedras || [])
    const [registrySpecializations, setRegistrySpecializations] = useState(specializations || [])
    const [registryStreams, setRegistryStreams] = useState(streams || [])
    const [registrySeminarGroups, setRegistrySeminarGroups] = useState(seminarGroups || [])
    const [registrySeminarGroupsSpecialization, setRegistrySeminarGroupsSpecialization] = useState(seminarGroupsSpecialization || [])

    const [myEvents, setMyEvents] = React.useState(eventsWeekly);
    const [registriesMode, setRegistriesMode] = React.useState(false);

    const [tempEvent, setTempEvent] = React.useState(null);
    const [isOpen, setOpen] = React.useState(false);
    const [isEdit, setEdit] = React.useState(false);
    const [anchor, setAnchor] = React.useState(null);
    const [start, startRef] = React.useState(null);
    const [end, endRef] = React.useState(null);
    const [popupEventTitle, setTitle] = React.useState('');
    const [popupEventTeacher, setTeacher] = React.useState('');
    const [popupEventRoom, setRoom] = React.useState('');
    const [popupEventAllDay, setAllDay] = React.useState(true);
    const [popupEventDate, setDate] = React.useState([]);
    const [popupEventStatus, setStatus] = React.useState('Лекция');
    const [mySelectedDate, setSelectedDate] = React.useState(new Date());
    const [colorPickerOpen, setColorPickerOpen] = React.useState(false);
    const [colorAnchor, setColorAnchor] = React.useState(null);
    const [selectedColor, setSelectedColor] = React.useState('');
    const [tempColor, setTempColor] = React.useState('');
    const colorPicker = React.useRef();
    const colorButtons = React.useMemo(() => {
        return ['cancel', {
            handler: () => {
                setSelectedColor(tempColor);
                setColorPickerOpen(false);
            },
            keyCode: 'enter',
            text: 'Save',
            cssClass: 'mbsc-popup-button-primary'
        }];
    }, [tempColor]);

    const validateEvents = (updatedEvents) => {
        updatedEvents.forEach(event => {
            let exists = eventsWeekly.find(el => (el.start === event.start && el.teacher === event.teacher && (event.room !==  el.room)))
            if (exists) {
                window.alert(`Преподаватель ${event.teacher} используется одновременно в нескольких занятиях`)
            }
        })
    }

    const loadLessons = async () => {
        try {
            const result = await apiService.getScheduleRegistry(LESSONS)
            if (result) setRegistryLessons(result)
        } catch(e){ console.log(e)}
    }

    const saveLessons = async (obj) => {
        try {
            const result = await apiService.updateScheduleRegistry(LESSONS, obj)
            if (result) setRegistryLessons(result)
        } catch(e){ console.log(e)}
    }

    const loadTeachers = async () => {
        try {
            const result = await apiService.getScheduleRegistry(TEACHERS)
            if (result) setRegistryTeachers(result)
        } catch(e){ console.log(e)}
    }

    const saveTeachers = async (obj) => {
        try {
            const result = await apiService.updateScheduleRegistry(TEACHERS, obj)
            if (result) setRegistryTeachers(result)
        } catch(e){ console.log(e)}
    }

    const loadTeachersTypes = async () => {
        try {
            const result = await apiService.getScheduleRegistry(TEACHERS_TYPES)
            if (result) setRegistryTeachersTypes(result)
        } catch(e){ console.log(e)}
    }

    const saveTeachersTypes = async (obj) => {
        try {
            const result = await apiService.updateScheduleRegistry(TEACHERS_TYPES, obj)
            if (result) setRegistryTeachersTypes(result)
        } catch(e){ console.log(e)}
    }

    const loadGroups = async () => {
        try {
            const result = await apiService.getScheduleRegistry(GROUPS)
            if (result) setRegistryGroups(result)
        } catch(e){ console.log(e)}
    }

    const saveGroups = async (obj) => {
        try {
            const result = await apiService.updateScheduleRegistry(GROUPS, obj)
            if (result) setRegistryGroups(result)
        } catch(e){ console.log(e)}
    }

    const loadRooms = async () => {
        try {
            const result = await apiService.getScheduleRegistry(ROOMS)
            if (result) setRegistryRooms(result)
        } catch(e){ console.log(e)}
    }

    const saveRooms = async (obj) => {
        try {
            const result = await apiService.updateScheduleRegistry(ROOMS, obj)
            if (result) setRegistryRooms(result)
        } catch(e){ console.log(e)}
    }

    const loadPlans = async () => {
        try {
            const result = await apiService.getScheduleRegistry(LEARNING_PLANS)
            if (result) setRegistryPlans(result)
        } catch(e){ console.log(e)}
    }

    const savePlans = async (obj) => {
        try {
            const result = await apiService.updateScheduleRegistry(LEARNING_PLANS, obj)
            if (result) setRegistryPlans(result)
        } catch(e){ console.log(e)}
    }

    const loadPlanTotal = async () => {
        try {
            const result = await apiService.getScheduleRegistry(LEARNING_PLAN_TOTAL)
            if (result) setRegistryPlansTotal(result)
        } catch(e){ console.log(e)}
    }

    const savePlanTotal = async (obj) => {
        try {
            const result = await apiService.updateScheduleRegistry(LEARNING_PLAN_TOTAL, obj)
            if (result) setRegistryPlansTotal(result)
        } catch(e){ console.log(e)}
    }

    const loadCafedras = async () => {
        try {
            const result = await apiService.getScheduleRegistry(CAFEDRAS)
            if (result) setRegistryCafedras(result)
        } catch(e){ console.log(e)}
    }

    const saveCafedras = async (obj) => {
        try {
            const result = await apiService.updateScheduleRegistry(CAFEDRAS, obj)
            if (result) setRegistryCafedras(result)
        } catch(e){ console.log(e)}
    }

    const loadStreams = async () => {
        try {
            const result = await apiService.getScheduleRegistry(STREAMS)
            if (result) setRegistryStreams(result)
        } catch(e){ console.log(e)}
    }

    const saveStreams = async (obj) => {
        try {
            const result = await apiService.updateScheduleRegistry(STREAMS, obj)
            if (result) setRegistryStreams(result)
        } catch (e) {
            console.log(e)
        }
    }

    const loadSpecializations = async () => {
        try {
            const result = await apiService.getScheduleRegistry(SPECIALIZATIONS)
            if (result) setRegistrySpecializations(result)
        } catch(e){ console.log(e)}
    }

    const saveSpecializations = async (obj) => {
        try {
            const result = await apiService.updateScheduleRegistry(SPECIALIZATIONS, obj)
            if (result) setRegistrySpecializations(result)
        } catch(e){ console.log(e)}
    }

    const loadSeminarGroups = async () => {
        try {
            const result = await apiService.getScheduleRegistry(SEMINAR_GROUPS)
            if (result) setRegistrySeminarGroups(result)
        } catch(e){ console.log(e)}
    }

    const saveSeminarGroups = async (obj) => {
        try {
            const result = await apiService.updateScheduleRegistry(SEMINAR_GROUPS, obj)
            if (result) setRegistrySeminarGroups(result)
        } catch(e){ console.log(e)}
    }

    const loadSeminarGroupsSpecializations = async () => {
        try {
            const result = await apiService.getScheduleRegistry(SEMINAR_GROUP_SPECIALISATIONS)
            if (result) setRegistrySeminarGroupsSpecialization(result)
        } catch(e){ console.log(e)}
    }

    const saveSeminarGroupsSpecializations = async (obj) => {
        try {
            const result = await apiService.updateScheduleRegistry(SEMINAR_GROUP_SPECIALISATIONS, obj)
            if (result) setRegistrySeminarGroupsSpecialization(result)
        } catch(e){ console.log(e)}
    }


    useEffect(() => {
        loadGroups()
        loadTeachers()
        loadTeachersTypes()
        loadRooms()
        loadLessons()
        loadPlans()
        loadPlanTotal()
        loadCafedras()
        loadStreams()
        loadSpecializations()
        loadSeminarGroups()
        loadSeminarGroupsSpecializations()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const saveEvent = React.useCallback(() => {
        const newEvent = {
            id: tempEvent.id,
            resource: tempEvent.resource,
            title: popupEventTitle,
            teacher: popupEventTeacher,
            type: popupEventStatus,
            room: popupEventRoom,
            start: popupEventDate[0],
            end: popupEventDate[1],
            allDay: popupEventAllDay,
            status: popupEventStatus,
            color: selectedColor
        };
        if (isEdit) {
            // update the event in the list
            const index = myEvents.findIndex(x => x.id === tempEvent.id);
            const newEventList = [...myEvents];

            newEventList.splice(index, 1, newEvent);
            setMyEvents(newEventList);
            validateEvents(newEventList)
            // here you can update the event in your storage as well
            // ...
        } else {
            // add the new event to the list
            setMyEvents([...myEvents, newEvent]);
            validateEvents([...myEvents, newEvent])
            // here you can add the event to your storage as well
            // ...
        }
        setSelectedDate(popupEventDate[0]);
        // close the popup
        setOpen(false);
    }, [isEdit, myEvents, popupEventAllDay, popupEventDate, popupEventTeacher, popupEventRoom, popupEventStatus, popupEventTitle, tempEvent, selectedColor]);

    const deleteEvent = React.useCallback((event) => {
        setMyEvents(myEvents.filter(item => item.id !== event.id));

        setTimeout(() => {
            snackbar({
                button: {
                    action: () => {
                        setMyEvents(prevEvents => [...prevEvents, event]);
                    },
                    text: 'Undo'
                },
                message: 'Event deleted'
            });
        });
    }, [myEvents]);

    const loadPopupForm = React.useCallback((event) => {
        setTitle(event.title);
        setTeacher(event?.teacher || event?.original?.teacher);
        setRoom(event?.room || event?.original?.room);
        setDate([event.start, event.end]);
        setAllDay(event.allDay || false);
        setStatus(event.type || event?.original?.type || 'Лекция');
        setSelectedColor(event.color || '');
    }, []);

    // handle popup form changes

    const titleChange = React.useCallback((ev) => {
        if (!ev.value) return null
        setTitle(ev.value);
    }, []);

    const teacherChange = React.useCallback((ev) => {
        if (!ev.value) return null
        setTeacher(ev.value);
    }, []);


    const roomChange = React.useCallback((ev) => {
        if (!ev.name) return null
        setRoom(ev.name);
    }, []);

    const allDayChange = React.useCallback((ev) => {
        setAllDay(ev.target.checked);
    }, []);

    const dateChange = React.useCallback((args) => {
        setDate(args.value);
    }, []);

    const statusChange = React.useCallback((ev) => {
        setStatus(ev.target.value);
    }, []);

    const onDeleteClick = React.useCallback(() => {
        deleteEvent(tempEvent);
        setOpen(false);
    }, [deleteEvent, tempEvent]);

    // scheduler options

    const onSelectedDateChange = React.useCallback((event) => {
        setSelectedDate(event.date);
    }, []);

    const onEventClick = React.useCallback((args) => {

        setEdit(true);
        setTempEvent({ ...args.event });
        // fill popup form with event data
        loadPopupForm(args.event);
        setAnchor(args.domEvent.target);
        setOpen(true);
    }, [loadPopupForm]);

    const onEventCreated = React.useCallback((args) => {

        setEdit(false);
        setTempEvent(args.event)
        // fill popup form with event data
        loadPopupForm(args.event);
        setAnchor(args.target);
        // open the popup
        setOpen(true);
    }, [loadPopupForm]);

    const onEventDeleted = React.useCallback((args) => {
        deleteEvent(args.event)
    }, [deleteEvent]);

    const onEventUpdated = React.useCallback((args) => {
        // here you can update the event in your storage as well, after drag & drop or resize
        // ...
    }, []);

    // datepicker options
    const controls = React.useMemo(() => popupEventAllDay ? ['date'] : ['datetime'], [popupEventAllDay]);
    const responsiveOptions = React.useMemo(() => popupEventAllDay ? {
        medium: {
            controls: ['calendar'],
            touchUi: false
        }
    } : {
        medium: {
            controls: ['calendar', 'time'],
            touchUi: false
        }
    }, [popupEventAllDay]);

    // popup options
    const headerText = React.useMemo(() => isEdit ? 'Редактировать' : 'Создать занятие', [isEdit]);
    const popupButtons = React.useMemo(() => {
        if (isEdit) {
            return ['cancel', {
                handler: () => {
                    saveEvent();
                },
                keyCode: 'enter',
                text: 'Сохранить',
                cssClass: 'mbsc-popup-button-primary'
            }];
        } else {
            return ['cancel', {
                handler: () => {
                    saveEvent();
                },
                keyCode: 'enter',
                text: 'Добавить',
                cssClass: 'mbsc-popup-button-primary'
            }];
        }
    }, [isEdit, saveEvent]);

    const onClose = React.useCallback(() => {
        if (!isEdit) {
            // refresh the list, if add popup was canceled, to remove the temporary event
            setMyEvents([...myEvents]);
        }
        setOpen(false);
    }, [isEdit, myEvents]);

    const selectColor = React.useCallback((color) => {
        setTempColor(color)
    }, []);


    const changeColor = React.useCallback((ev) => {
        const color = ev.currentTarget.getAttribute('data-value');
        selectColor(color);
        if (!colorPicker.current.s.buttons.length) {
            setSelectedColor(color);
            setColorPickerOpen(false);
        }
    }, [selectColor, setSelectedColor]);

    const view = () => {
        return {
            schedule: {
                type: 'day',
                allDay: false,
                startTime: '11:00',
                endTime: '19:00'
            }
        };
    };

    const getNextDate = (index) => {
        return days[index]
    }


    const responsivePopup = {
        medium: {
            display: 'anchored',
            width: 400,
            fullScreen: false,
            touchUi: false
        }
    };
    const colorPopup = {
        medium: {
            display: 'anchored',
            touchUi: false,
            buttons: []
        }
    }
    const colors = ['#ffeb3c', '#ff9900', '#f44437', '#ea1e63', '#9c26b0', '#3f51b5', '', '#009788', '#4baf4f', '#7e5d4e'];

    const renderDay = (args) => {
        //const date = args.date;

        return <div className="header-template-container">
            <div className="header-template-date">
                <div className="header-template-day-name">{'Понедельник'}</div>
                {/*<div className="header-template-day">{formatDate('MMMM DD', date)}</div>*/}
            </div>

        </div>;
    }

    const renderCustomResource = (resource) => {
        return <div className="header-resource-template-content">
            <div className="header-resource-name">{resource.name}</div>
        </div>;
    }

    const renderScheduleEvent = React.useCallback((data) => {

        const types = {
            'Лекция' : '#dbe7fa',
            'Семинар' : '#ddfadb',
            'Практика' : '#a1e0e8'
        }

        //console.log(data)
        return <div className="md-custom-event-cont" style={{borderRadius: 6, background: types[data.original?.type || 'Практика'], height: '100%', minWidth: 200}}>
            <div className="md-custom-event-wrapper" style={{padding: 10}}>
                <div className="md-custom-event-details" style={{display: "flex", flexDirection: 'column', gap: 5}}>
                    <div className="md-custom-event-title" style={{fontSize: 12, fontWeight: 600, color: '#3a3a3a'}}>{data.title.substr(0, 40)}</div>
                    <div className="md-custom-event-title" style={{fontSize: 12, fontWeight: 400, color: '#3a3a3a'}}>{data.original?.teacher || ''} / {data.original?.room || ''}</div>
                    <div className="md-custom-event-time" style={{fontSize: 10, fontWeight: 400, color: '#3a3a3a'}}>{data.start} - {data.end}</div>
                </div>
            </div>
        </div>
    });

    const initAutoFill = () => {
        setMyEvents([])
        autoFillIndex=0;
        timer = setInterval(() => {
            if (autoFillIndex < eventsWeekly.length) {
                setMyEvents(eventsWeekly.slice(0, autoFillIndex));
                autoFillIndex++
            } else {
                clearInterval(timer)
            }
        }, 150)
    }

    const currentTeacherObject = popupEventTeacher ? registryTeachers.find(el => el.name === popupEventTeacher) || null : null
    const currentRoomObject = popupEventRoom ? registryRooms.find(el => el.name === popupEventRoom) || null : null

    const filteredTeachers = popupEventTitle ? registryTeachers.filter(el => el.lessons === popupEventTitle) : registryTeachers
    const filteredRooms = popupEventStatus ? registryRooms.filter(el => el.type?.toLowerCase() === popupEventStatus?.toLowerCase()) : registryRooms

    const customEmptyHeader = () => {
        return <React.Fragment />
    }

    return (
        <div className="mt-20">

            {!registriesMode && <div className={'mb-20 d-flex-ac'} style={{gap: 10}}>
                <button className={'button'} onClick={() => setRegistriesMode(true)}>Реестры</button>
                {/*<button className={'button'} onClick={() => initAutoFill()}>Заполнить</button>*/}
                {/*<button className={'button-alt'} onClick={() => setMyEvents([])}>Очистить</button>*/}
            </div>}

            {registriesMode && <div className={'mb-20 d-flex-ac'} style={{gap: 10}}>
                <button className={'button'} onClick={() => setRegistriesMode(false)}>Назад к расписанию</button>
            </div>}

            {registriesMode && <div className={'mb-20 d-flex-ac'} style={{gap: 10}}>
                <button className={'button-link'} style={{color: registriesPage === 0 ? 'black' : '#32B9F1'}} onClick={() => setRegistriesPage(0)}>Группы</button>
                <button className={'button-link'} style={{color: registriesPage === 1 ? 'black' : '#32B9F1'}} onClick={() => setRegistriesPage(1)}>Потоки</button>
                <button className={'button-link'} style={{color: registriesPage === 2 ? 'black' : '#32B9F1'}} onClick={() => setRegistriesPage(2)}>Семинарские</button>
                <button className={'button-link'} style={{color: registriesPage === 3 ? 'black' : '#32B9F1'}} onClick={() => setRegistriesPage(3)}>Семинарские спец</button>
                <button className={'button-link'} style={{color: registriesPage === 4 ? 'black' : '#32B9F1'}} onClick={() => setRegistriesPage(4)}>Кафедры</button>
                <button className={'button-link'} style={{color: registriesPage === 5 ? 'black' : '#32B9F1'}} onClick={() => setRegistriesPage(5)}>Предметы</button>
                <button className={'button-link'} style={{color: registriesPage === 6 ? 'black' : '#32B9F1'}} onClick={() => setRegistriesPage(6)}>Преподаватели</button>
                <button className={'button-link'} style={{color: registriesPage === 7 ? 'black' : '#32B9F1'}} onClick={() => setRegistriesPage(7)}>Аудитории</button>
                <button className={'button-link'} style={{color: registriesPage === 8 ? 'black' : '#32B9F1'}} onClick={() => setRegistriesPage(8)}>Специализации</button>
                <button className={'button-link'} style={{color: registriesPage === 9 ? 'black' : '#32B9F1'}} onClick={() => setRegistriesPage(9)}>Учебный план</button>
                <button className={'button-link'} style={{color: registriesPage === 10 ? 'black' : '#32B9F1'}} onClick={() => setRegistriesPage(10)}>График прохождения</button>
                <button className={'button-link'} style={{color: registriesPage === 11 ? 'black' : '#32B9F1'}} onClick={() => setRegistriesPage(11)}>Преподаватели(реестр)</button>
            </div>}

            {registriesMode && <div>

                {registriesPage === 0 && <GridGroups specializations={registrySpecializations} registry={registryGroups} updateRegistry={saveGroups} />}

                {registriesPage === 1 && <GridStreams groups={registryGroups} registry={registryStreams} updateRegistry={saveStreams} />}

                {registriesPage === 2 && <GridSeminarGroups groups={registryGroups} registry={registrySeminarGroups} updateRegistry={saveSeminarGroups} />}

                {registriesPage === 3 && <GridSeminarSpecGroups groups={registryGroups} specializations={registrySpecializations} registry={registrySeminarGroupsSpecialization} updateRegistry={saveSeminarGroupsSpecializations} />}

                {registriesPage === 4 && <GridCafedras registry={registryCafedras} updateRegistry={saveCafedras} />}

                {registriesPage === 5 && <GridLessons registry={registryLessons} updateRegistry={saveLessons}/>}

                {registriesPage === 6 && <GridTeachers
                    teachers={registryTeachers}
                    streams={registryStreams}
                    seminars={registrySeminarGroups}
                    seminarSpecs={registrySeminarGroupsSpecialization}
                    specializations={registrySpecializations}
                    cafedras={registryCafedras}
                    lessons={registryLessons}
                    registry={registryTeachersTypes}
                    updateRegistry={saveTeachersTypes} />}

                {registriesPage === 7 && <GridRooms registry={registryRooms} updateRegistry={saveRooms}/>}

                {registriesPage === 8 && <GridSpecializations registry={registrySpecializations} updateRegistry={saveSpecializations} groups={registryGroups}/>}

                {registriesPage === 9 && <GridPlansTotal specializations={registrySpecializations} lessons={registryLessons} registry={registryPlansTotal} updateRegistry={savePlanTotal}/>}

                {registriesPage === 10 && <GridPlans specializations={registrySpecializations} lessons={registryLessons} registry={registryPlans} updateRegistry={savePlans}/>}

                {registriesPage === 11 && <GridTeachersNames cafedras={registryCafedras}  registry={registryTeachers} updateRegistry={saveTeachers} />}

            </div>}

            {!registriesMode && <div>

                {days.map((day, dayIndex) => <Eventcalendar
                    selectedDate={getNextDate(dayIndex)}
                    key={dayIndex}
                    theme="ios"
                    themeVariant="light"
                    clickToCreate={true}
                    dragToCreate={true}
                    dragToMove={true}
                    dragToResize={true}
                    locale={localeRu}
                    eventDelete={true}
                    view={view(day)}
                    data={myEvents}
                    resources={groups}
                    groupBy="date"
                    renderDay={renderDay}
                    renderScheduleEvent={renderScheduleEvent}
                    renderResource={renderCustomResource}
                    onEventClick={onEventClick}
                    onEventCreated={onEventCreated}
                    onEventDeleted={onEventDeleted}
                    onEventUpdated={onEventUpdated}
                    renderHeader={customEmptyHeader}
                />)}


                <Popup
                    locale={localeRu}
                    display="bottom"
                    fullScreen={true}
                    contentPadding={false}
                    headerText={headerText}
                    anchor={anchor}
                    buttons={popupButtons}
                    isOpen={isOpen}
                    onClose={onClose}
                    responsive={responsivePopup}
                >
                    <div className="mbsc-form-group">
                        <Select
                            defaultValue={popupEventTitle}
                            value={popupEventTitle}
                            onChange={titleChange}
                            data={registryLessons.map(el => {
                                return {
                                    ...el,
                                    text: el.value
                                }
                            })}
                            display="anchored"
                            filter={true}
                            label="Предмет"
                            inputProps={inputProps}
                        />
                        <SegmentedGroup onChange={statusChange}>
                            <SegmentedItem value="Лекция" checked={popupEventStatus === 'Лекция'}>Лекция</SegmentedItem>
                            <SegmentedItem value="Семинар" checked={popupEventStatus === 'Семинар'}>Семинар</SegmentedItem>
                            <SegmentedItem value="Практика" checked={popupEventStatus === 'Практика'}>Практика</SegmentedItem>
                        </SegmentedGroup>
                        <Select
                            defaultValue={popupEventTeacher}
                            value={popupEventTeacher}
                            onChange={teacherChange}
                            data={filteredTeachers.map(el => {
                                return {
                                    ...el,
                                    text: el.value
                                }
                            })}
                            display="anchored"
                            filter={true}
                            label="Преподаватель"
                            inputProps={inputProps}
                        />
                        {currentTeacherObject?.comment && <div style={{marginTop: -15}} className={'ml-20 fs-12'}>
                            {`❗️ ${currentTeacherObject?.comment} `}
                        </div>}
                        <Select
                            defaultValue={popupEventRoom}
                            value={popupEventRoom}
                            onChange={roomChange}
                            data={filteredRooms.map(el => {
                                return {
                                    ...el,
                                    text: el.name
                                }
                            })}
                            display="anchored"
                            filter={true}
                            label="Аудитория"
                            inputProps={inputProps}
                        />
                        {currentRoomObject?.comment && <div style={{marginTop: -15}} className={'ml-20 fs-12'}>
                            {`${currentRoomObject?.comment}`}
                        </div>}

                    </div>
                    <div className="mbsc-form-group">
                        <Input ref={startRef} label="Начало" />
                        <Input ref={endRef} label="Конец" />
                        <Datepicker
                            select="range"
                            controls={controls}
                            touchUi={true}
                            startInput={start}
                            endInput={end}
                            showRangeLabels={false}
                            responsive={responsiveOptions}
                            onChange={dateChange}
                            value={popupEventDate}
                        />
                        {isEdit && <div className="mbsc-button-group">
                            <Button className="mbsc-button-block" color="danger" variant="outline" onClick={onDeleteClick}>Удалить занятие</Button>
                        </div>}
                    </div>
                </Popup>
                <Popup
                    display="bottom"
                    contentPadding={false}
                    showArrow={false}
                    showOverlay={false}
                    anchor={colorAnchor}
                    isOpen={colorPickerOpen}
                    buttons={colorButtons}
                    responsive={colorPopup}
                    ref={colorPicker}
                >
                    <div className="crud-color-row">
                        {colors.map((color, index) => {
                            if (index < 5) {
                                return <div key={index} onClick={changeColor} className={"crud-color-c " + (tempColor === color ? 'selected' : '')} data-value={color}>
                                    <div className="crud-color mbsc-icon mbsc-font-icon mbsc-icon-material-check" style={{ background: color }}></div>
                                </div>
                            } else return null;
                        })}
                    </div>
                    <div className="crud-color-row">
                        {colors.map((color, index) => {
                            if (index >= 5) {
                                return <div key={index} onClick={changeColor} className={"crud-color-c " + (tempColor === color ? 'selected' : '')} data-value={color}>
                                    <div className="crud-color mbsc-icon mbsc-font-icon mbsc-icon-material-check" style={{ background: color }}></div>
                                </div>
                            } else return null;
                        })}
                    </div>
                </Popup>

            </div>}

        </div>
    )

}

const mapStateToProps = ({ user: { data, section, city }}) => {
    return { data, section, city };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dataItemUpdate: dataItemUpdate(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SchedulerUniverView);
