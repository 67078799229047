import {isUniversity} from "../utils/keys";

const updateLanguage = (state, action) => {

    if (state === undefined) {
        return {
             texts: {
                 screens: {
                     loginScreen: {
                         loginText: 'Введите имя пользователя',
                         passText: 'Введите пароль',
                         saveMeText: 'Сохранить настройки входа',
                         loginNote: 'Вы можете получить имя пользователя в РУП Белтелеком',
                     },
                     adminPage: {
                         headingText: isUniversity ? 'Мой университет' : 'Мой город',
                         subheadingText: isUniversity ? 'Администрирование факультета' : 'Система администрирования учреждений',
                         exitText: 'Выход',
                         previewText: 'Предварительный просмотр',
                         supportText: 'Техническая поддержка',
                         phonesText: {
                             baranovichi: ['8-0163-61-73-08'],
                             beshenkovichi: ['8-02131-6-41-90'],
                             bobruisk: ['8-0225-74-74-18'],
                             borisov: ['8-0177-93-02-78'],
                             braslav: ['8-0215-36-04-53'],
                             verhnedvinsk: ['8-02151-5-01-22'],
                             vileyka: ['8-01771- 5-53-31', '8-029-768-80-94'],
                             vitebsk: ['(0212) 511133'],
                             gancevichi: ['8-01646-6-23-06'],
                             glubokoe: ['8-0215-64-10-00'],
                             gorodok: ['8-029-297-27-82'],
                             dzerzhinsk: ['8-01716-9-94-55'],
                             dobrush: ['8-02333 -5-05-70', '8-02333-2-32-05'],
                             dokshicy: ['8-02157-2-90-00'],
                             drogichin: ['8-01644-3-23-99'],
                             dubrovno: ['8-02137-4-18-42'],
                             dyatlovo: ['8-01563-2-24-40'],
                             zhabinka: ['8-0164-13-51-01', '8-0164-13-52-25'],
                             zhlobin: ['8-02334-3-31-15'],
                             zhodino: ['8-01775-5-00-03'],
                             zelva: ['8-01564-7-04-04'],
                             ivanovo: ['8-01652-2-11-90'],
                             kalinkovichi: ['8-0234-52-45-90'],
                             kobrin: ['8-0164-23-15-31'],
                             lepel: ['8-0213-23-75-03'],
                             liozno: ['8-02138-5-64-07', '8-02138-5-20-58'],
                             logoisk: ['8-0177-45-24-61'],
                             luninets: ['8-01647-6-48-10', '8-01647-6-45-40'],
                             lyakhivichi: ['8-01633-6-63-25'],
                             miory: ['8-02152-42260', '8-02152-42350'],
                             mogilev: ['8-222-60-60-88'],
                             mozyr: ['80236-25-11-11'],
                             molodechno: ['8-0176-74-75-76'],
                             myadel: ['8-01797-4-07-09', '8-01797-4-07-01'],
                             narovlya: ['8-0235-54-42-99'],
                             nesvizh: ['8-01770-5-17-20'],
                             novopolotsk: ['8-0214-51-47-70'],
                             oktyabrskiy: ['8-02356-3-87-20'],
                             orsha: ['8-0216-54-00-10'],
                             osipovichi: ['8-0223-55-01-50'],
                             pinsk: ['8-0165-65-66-70'],
                             polotsk: ['8-0214-74-00-00', '8-0214-46-55-99'],
                             postavy: ['8-02155-5-11-66', '8-02155-4-22-22'],
                             rechitsa: ['8-02340-9-94-55'],
                             rossony: ['8-02159-5-14-07'],
                             svisloch: ['8-01513-7-09-87'],
                             senno: ['8-02135-5-00-00'],
                             smorgon: ['8(01592) 41650', '8(01592) 42715'],
                             soligorsk: ['8-033-636-81-66'],
                             dorogi: ['8-01792-30000'],
                             stolbcy: ['8-029-5578324'],
                             stolin: ['8-01655-6-60-13', '8-01655-6-00-09', '8-1655-2-80-54'],
                             telehany: ['8-01645-9-28-88 '],
                             tolochyn: ['8-02136-2-17-50'],
                             uzda: ['8-0171-86-55-08'],
                             ushachi: ['8-02158-5-23-50'],
                             chashniki: ['8-02133-6-09-99'],
                             sharkovschina: ['8-02154-4-15-89'],
                             shumilino: ['8-02130-5-44-03']
                         },
                     },
                 },
                 components: {
                     imageComponent: {
                         placeholderText: 'Изображение отсутствует',
                         descriptionText: 'Для оптимальной скорости загрузки ваших изображений в мобильном приложении, мы рекомендуем не использовать изображения больше, чем 640px по ширине',
                         loadingText: 'Загрузка изображения',
                         urlText: 'Изображение по ссылке',
                     },
                     saveComponent: {
                         saveText: 'Сохранить',
                     },
                     headingBlockComponent: {
                         headingText: 'Заголовок',
                         contentText: 'Текстовый блок',
                     },
                     serviceDirectoryComponent: {
                         directoryHeadingText: 'Название категории услуг и сервисов',
                     },
                     newsComponent: {
                         newsHeadingText: 'Новость',
                         newsTitleText: 'Заголовок новости',
                         newsDateText: 'Дата публикации',
                         newsTimeText: 'Время публикации',
                         newsMainText: 'Событие',
                         pollMainText: 'Опрос',
                         menuMainText: 'Меню',
                         newsOffersText: 'Акция',
                         vacancyOffersText: 'Вакансия',
                         newsSpecialText: 'Важные новости',
                         newsDeleteText: 'Удаление новости',
                         newsExpDateText: 'Дата удаления',
                         newsExpTimeText: 'Время удаления',
                         newsMainDateText: 'Дата события',
                         newsContentText: 'Текст новости',
                         newsImageText: 'Изображение к новости',
                         newsUrlText: 'Ссылка на веб-сайт',
                         newsPreviewHeadingText: 'Редактировать заголовок новости',
                         newsPreviewContentText: 'Редактировать текст новости',
                     },
                     serviceComponent: {
                         serviceHeadingText: 'Сервис',
                         serviceTitleText: 'Название услуги',
                         serviceContentText: 'Описание услуги',
                         servicePriceText: 'Цена услуги, руб',
                         serviceImageText: 'Изображение услуги',
                         serviceUrlText: 'Ссылка на веб-сайт',
                         serviceEmailText: 'Email для уведомлений о записи',
                     },
                     formItemMenu: {
                         hideText: 'Скрыть',
                         showText: 'Показать',
                         deleteText: 'Удалить',
                         upText: 'Выше ',
                         downText: 'Ниже',
                     },
                 },
                 validationErrors: {
                     emailText: 'Вы ввели неверный email',
                     urlText: 'Вы ввели неверный адрес',
                     emptyText: 'Поле ввода не должно быть пустым',
                     minText: 'Текст в поле ввода слишком короткий',
                     maxText: 'Текст в поле ввода слишком длинный',
                 },
                 resErrors: {
                     res401: 'Произошла ошибка авторизации. Проверьте правильность имени пользователя и пароля. При необходимости, зайдите в систему заново.',
                     res403: 'Вы не можетете получить доступ к запрашиваемой информации. Проверьте правильность имени пользователя и пароля. При необходимости, зайдите в систему заново.',
                     res404: 'Запрашиваемая информация не найдена. Проверьте правильность имени пользователя и пароля. При необходимости, зайдите в систему заново.',
                     res400: 'Произошла ошибка в работе системы. Проверьте, что вы подключены к интернету. Если с соединением все в порядке, сообщите разработчикам об ошибке, написав на email: moygoroddev@gmail.com',
                 },
            },
        };
    }

    switch (action.type) {

        case 'FETCH_LNG_SUCCESS': {

            return {
                ...state.lng,
                texts: action.payload,

            }
        }

        default:
            return state.language;
    }
};

export default updateLanguage;
