import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import { DataGrid, ruRU, useGridApiContext } from '@mui/x-data-grid';
import {FiX} from "react-icons/fi";
import {dataItemUpdate} from "../../../../actions";
import Multiselect from 'multiselect-react-dropdown';
import {v4 as uuidv4} from "uuid";

const lessonTypes = [
    {name: 'Лекция'},
    {name: 'Семинар'},
    {name: 'Практика'},
]

const setIDs = (arr) => {
    return (arr || []).map((el, elIndex) => {
        return {
            ...el,
            id:elIndex
        }
    })
}

const GridPlansTotal = ({ registry, updateRegistry, lessons, specializations }) => {

    const [rows, setRows] = useState(setIDs(registry) || [])
    const [filter, setFilter] = useState('Все')

    function LessonsEditInputCell(props) {
        const { id, value, field } = props;
        const apiRef = useGridApiContext();

        const handleChange = (event, newValue) => {
            apiRef.current.setEditCellValue({ id, field, value: newValue.map(el => el.name) });
        };

        const secondaryOptions = (lessons || []).map((item, index) => {return {name: item.value, id: index}})
        const secondaryOptionsSelected = (value || []).map((item, index) => {return {name: item, id: index}})

        return (
            <div className={'p-20'} style={{position: 'absolute', background: '#FFF' }}>
            <Multiselect placeholder={'Добавить'}
                options={secondaryOptions}
                selectedValues={secondaryOptionsSelected}
                onSelect={(selectedList) => handleChange(null, selectedList)}
                onRemove={(selectedList) => handleChange(null, selectedList)}
                displayValue="name" // Property name to display in the dropdown options
            />
            </div>
        );
    }

    function LessonsTypesEditInputCell(props) {
        const { id, value, field } = props;
        const apiRef = useGridApiContext();

        const handleChange = (event, newValue) => {
            apiRef.current.setEditCellValue({ id, field, value: newValue.map(el => el.name) });
        };

        const secondaryOptions = (lessonTypes || []).map((item, index) => {return {name: item.name, id: index}})
        const secondaryOptionsSelected = (value || []).map((item, index) => {return {name: item, id: index}})

        return (
            <div className={'p-20'} style={{position: 'absolute', background: '#FFF' }}>
                <Multiselect placeholder={'Добавить'}
                             options={secondaryOptions}
                             selectedValues={secondaryOptionsSelected}
                             onSelect={(selectedList) => handleChange(null, selectedList)}
                             onRemove={(selectedList) => handleChange(null, selectedList)}
                             displayValue="name" // Property name to display in the dropdown options
                />
            </div>
        );
    }

    const renderLessonsEditInputCell = (params) => {
        return <LessonsEditInputCell {...params} />;
    };

    const renderLessonsTypesEditInputCell = (params) => {
        return <LessonsTypesEditInputCell {...params} />;
    };


    useEffect(() => {
        setRows(setIDs(registry) || [])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(registry)])

    const addRow = () => {
        const rowsCopy = [...rows]
        rowsCopy.push({
            id: uuidv4(),
            name: 'Новая запись',
            comment: '',
        })
        updateRegistry(rowsCopy)
    }

    const deleteRow = (row) => {
        const rowsCopy = [...rows]
        const existedRowIndex = rowsCopy.findIndex(el => el.id === row.id)
        if (existedRowIndex > -1) {
            rowsCopy.splice(existedRowIndex, 1)
        }
        updateRegistry(rowsCopy)

    }

    const processRowUpdate = (newRow) => {
        const rowsCopy = [...rows]
        const existedRowIndex = rowsCopy.findIndex(el => el.id === newRow.id)
        if (existedRowIndex > -1) {
            rowsCopy[existedRowIndex] = newRow
        }
        const updatedRow = { ...newRow, isNew: false };
        updateRegistry(rowsCopy);
        return updatedRow;
    };


    const columns = [
        { field: 'lesson', headerName: 'Предмет', sortable: true, editable: true, width: 350, type: 'singleSelect', valueOptions: (lessons || []).map(el => el.value) },
        { field: 'lections', headerName: 'Лекции', sortable: true, editable: true, width: 150, type: 'number' },
        { field: 'practice', headerName: 'Практические', sortable: true, editable: true, width: 150, type: 'number' },
        { field: 'seminars', headerName: 'Семинары', sortable: true, editable: true, width: 150, type: 'number' },
        { field: 'self', headerName: 'Самостоятельное', sortable: true, editable: true, width: 150, type: 'number' },
        { field: 'sem1', headerName: 'Семестр   ', sortable: true, editable: true, width: 150, type: 'number' },
        { field: 'course', headerName: 'Курс', sortable: true, width: 100, editable: true, type: 'number'},
        //{ field: 'sem2', headerName: 'Семестр 8', sortable: true, editable: true, width: 150, type: 'number' },
        { field: 'delete', headerName: '', sortable: false, width: 50,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
                        <FiX className='enabledClick' color='#000000' size={20} onClick={() => window.confirm('Вы уверены, что хотите удалить строку?') && deleteRow(params.row)} />
                    </div>
                );
            }
        },
    ];


    if (!Array.isArray(rows)) {
        return null
    }

    const filterList = ['Все'];
    rows.forEach(el => {
        const exists = filterList.find(listEl => listEl === el.lesson)
        if (!exists) filterList.push(el.lesson)
    })

    let filteredRows = rows
    if (filter !== 'Все') {
        filteredRows = rows.filter(el => el.lesson === filter)
    }

    return (
        <div>
            <h3 className={'mt-20 mb-20'}>Учебный план</h3>
            {/*<select value={filter} onChange={e=>setFilter(e.target.value)}>
                {filterList.map((item, index) => <option key={index} value={item}>{item}</option>)}
            </select>*/}
            <button className={'button-link mt-10 mb-10'} onClick={() => addRow()}>Добавить</button>
            <div className='white' style={{ width: '100%', height: 600 }}>
                <div style={{ height: '100%', width: '100%', fontSize: 12 }}>
                    <DataGrid
                        processRowUpdate={processRowUpdate}
                        onProcessRowUpdateError={(e) => console.log(e)}
                        getRowId={(row) => row.id}
                        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                        rows={filteredRows}
                        columns={columns}
                    />
                </div>
            </div>
        </div>
    )

}

const mapStateToProps = ({ user: { data, section }}) => {
    return { data, section };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dataItemUpdate: dataItemUpdate(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GridPlansTotal);
